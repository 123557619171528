@import 'styles/breakpoints';

.producer-contact {
  p {
    line-height: 1.6;
  }

  .hero-img {
    object-position: 75% 75%;

    @include until($widthTablet) {
      object-position: center top;
    }
  }

  .info-section {
    padding-top: calc(var(--spacing-900) + var(--spacing-500));
    padding-bottom: var(--spacing-900);

    @include until($widthTablet) {
      padding: var(--spacing-600) 0;
      min-height: unset;
    }

    :global(.container) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: var(--spacing-600);
      row-gap: var(--spacing-900);

      @include until($widthTablet) {
        grid-template-columns: 1fr;
        row-gap: var(--spacing-600);
      }
    }

    .info-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include until($widthTablet) {
        display: none;
      }

      .producers-image {
        height: 100%;
        width: 100%;
        position: relative;

        @include until($widthTablet) {
          width: 100%;
        }
      }
    }
  }

  .conditions-section {
    padding-top: var(--spacing-900);
    padding-bottom: var(--spacing-900);
    height: auto;

    @include until($widthTablet) {
      padding: var(--spacing-600) 0;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-500);

      p,
      h2 {
        margin: 0;
      }
    }

    .conditions-info {
      display: grid;
      grid-template-columns: 24px auto;
      gap: var(--spacing-400);
      padding: var(--spacing-400);
      background-color: var(--bg-layer);
      align-items: center;

      svg {
        width: 24px;
        color: var(--text-primary);
      }
    }

    .conditions-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .conditions-image {
        height: 100%;
        width: 100%;
        max-height: 400px;
        position: relative;
        object-fit: contain;
      }
    }
  }

  .producers-section {
    padding-bottom: var(--spacing-900);

    @include until($widthDesktop) {
      overflow: visible;
    }

    @include until($widthTablet) {
      padding-bottom: var(--spacing-600);
    }

    .intro {
      max-width: 730px;
      margin-bottom: var(--spacing-600);
    }

    .scrollable-wrapper {
      @include until($widthDesktop) {
        width: 100%;
        overflow-x: auto;
        padding: 0 var(--spacing-700);
      }

      @include until($widthTablet) {
        padding: 0 var(--spacing-500);
      }
    }

    .producers {
      display: grid;
      gap: var(--spacing-500);
      grid-template-columns: repeat(4, minmax(0, 1fr));

      @include until($widthDesktop) {
        width: calc((4 * 290px) + (3 * #{var(--spacing-500)}));
        grid-template-columns: repeat(4, 290px);
      }
    }
  }
}
